



























































































import { Component, Vue } from "vue-property-decorator";
import { IProjectCreate } from "@/interfaces";
import { readProject } from "@/store/main/getters";
import {
  dispatchUpdateProject,
  dispatchGetProject,
} from "@/store/main/actions";
import { MAP_PROJECT_MODES } from '@/utils';

@Component
export default class Create extends Vue {
  public loading = true;
  public valid = false;
  public project: IProjectCreate = {
    name: "",
    key: "",
    api_key: "",
    location: "",
    wifi: "",
    password: "",
    state: "",
    client: "",
    identification_code: "",
    mqtt: "",
    // is_active: true,
    has_local: false,
    energy_price: null,
    mode: "",
  };
  public modes = MAP_PROJECT_MODES;

  get getProject() {
    return readProject(this.$store);
  }

  public reset() {
    this.project = <IProjectCreate>{ ...this.getProject };
    this.$validator.reset();
  }

  public cancel() {
    this.$router.push("/main/projects/all");
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.project.energy_price === ""){
        this.project.energy_price = null;
      }
      await dispatchUpdateProject(this.$store, { projectId: Number(this.$router.currentRoute.params.id), project: this.project});
      this.$router.push("/main/projects/all");
    }
  }

  public async mounted() {
    await dispatchGetProject(this.$store, Number(this.$router.currentRoute.params.id));
    this.project = <IProjectCreate>{ ...this.getProject};
    this.loading = false;
  }
}
